import { React, useEffect, useState } from 'react'
import './Header.css'
import { Link, useNavigate } from 'react-router-dom'
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { AiOutlineClose } from 'react-icons/ai'
import { SlArrowDown } from 'react-icons/sl'
import logo from "../assets/logo.png"
import logowhite from "../assets/logowhite.png"

function Header() {
    const navigate = useNavigate()
    const [menu, setMenu] = useState(false)
    const [dropdown, setDropdown] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const headerStyle = {
      backgroundColor: scrollPosition > 0 ? '#d7d2cb' : 'transparent',
      transition: 'background-color 0.3s ease-in-out',
    };

    return (
      <div className='header'  style={headerStyle}>      
        <Link to='/'><img src={logo} alt='' className='portfolio_header' /></Link>
        <div>
          <ul className='header_nav'>
            <li>
              <Link to='/story' className='header_nav_list'>My Story</Link>
            </li>
            <li className='dropdown' onClick={() => {setDropdown(!dropdown)}}>         
              <div className='dropdown_div_first-child'>
                <Link to='#' className='header_nav_list'>My portfolios</Link>
                <SlArrowDown className='header_arrow'/>
              </div>
              <div className={dropdown? 'header_dropdown_div' : 'header_dropdown_div_false'}>
                <h1 onClick={() => {navigate('/engineering')}}>Mechanical engineer</h1>
                <h1 onClick={() => {navigate('/developer')}}>Software developer</h1>
                <h1 onClick={() => {navigate('/design')}}>Design</h1>
                <h1 onClick={() => {navigate('/writer')}}>Writer</h1>
              </div>
            </li>
            <li>
              <Link to='/insight' className='header_nav_list'>Insight</Link>
            </li>
            <li>
              <Link to='/contact' className='header_nav_list'>Contact</Link>
            </li>
          </ul>
        </div>

        <HiMiniBars3BottomRight className='home_menu' onClick={() => {setMenu(true)}}/>
        <div className={menu? 'home_sidebar_true' : 'home_sidebar'}>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <Link to='/'><img src={logowhite} alt='' className='portfolio_header2' /></Link>
              <AiOutlineClose className='home_sidebar_close' onClick={() => {setMenu(false)}}/>
            </div>
            <ul className='home_sidebar_navbar'>
              <li><Link
                to='/story'
                className='home_sidebar_link'
                >My story</Link></li>
              <li><Link
                  onClick={() => {setDropdown(!dropdown)}}
                  to="#"
                  className='home_sidebar_link'
                  >My portfolios</Link></li>
                  <div className={dropdown? 'header_dropdown_div' : 'header_dropdown_div_false'}>
              <h1  onClick={() => {navigate('/engineering')}}>Mechanical engineer</h1>
              <h1 onClick={() => {navigate('/developer')}}>Software developer</h1>
              <h1 onClick={() => {navigate('/design')}}>Design</h1>
              <h1 onClick={() => {navigate('/writer')}}>Writer</h1>
              </div>
              <li><Link
                  to='/insight'
                  className='home_sidebar_link'
                  >Insight</Link></li>
              <li><Link
                  to='/contact'
                  className='home_sidebar_link'
                  >Contact</Link></li>
            </ul>
        </div>
    </div>
    )
}

export default Header