import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Design from './pages/Design/Design';
import Engineering from './pages/Engineering/Enginneering';
import Writer from './pages/Writer/Writer';
import Developer from './pages/Developer/Developer';
import Story from './pages/Story/Story';
import Contact from './pages/Contact/Contact';
import Insight from './pages/Insight/Insight';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/design' element={<Design />} />
          <Route path='/engineering' element={<Engineering />} />
          {//<Route path='/writer' element={<Writer />} />
          }
          <Route path='/developer' element={<Developer />} />
          <Route path='/story' element={<Story />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/insight' element={<Insight />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
