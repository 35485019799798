import './Home.css'
import { Link, useNavigate } from 'react-router-dom'
import { SlArrowDown } from 'react-icons/sl'
import { BsSuitDiamondFill } from 'react-icons/bs'
import Header2 from '../../components/Header2';
import Footer from '../../components/Footer';
import { Parallax } from 'react-scroll-parallax';
function Home() {
    const navigate = useNavigate()
    
    return(
        <div>
          <Header2 />
            <div className='home_body'>
                <section className='home_section_one'>
                {/* <div className='section_two_bg'>
                    <div className='section_two_fg'>
                      <div className='primary_one'>
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                      </div>
                      <div className='primary_two'>
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                        <BsSuitDiamondFill />   
                        <h1>If you know me, you will know my story</h1>
                      </div>
                      
                    </div>
                  </div> */}
                  
                  <Parallax speed={-15}>
                    <div className='home_section_one_hero'>

                    <h1>Just call me Harry.</h1>
                      <br />
                      <p>My name is  <span style={{color: 'white'}}> AZAMOSA HARRY</span> and I am a beautiful mosaic of lived experience and culture.</p>
                      <br />

                      <section className='home__section__xx'>
                    <div className='wrapper'>
                      <div className='static-text'>I'm a</div>
                      <ul className='dynamic-text'>
                        <li><span>Mechanical engineer</span></li>
                        <li><span>Software developer</span></li>
                        <li><span>Creative Designer</span></li>
                        <li><span>Researcher and Writer</span></li>
                      </ul>
                    </div>
                  </section>
                  {/* <br />
                      <p>I am a Mechanical engineer, Software developer, designer and writer. Yes, I enjoy the diversity of knowledge!</p> */}
                      </div>
                      <div className='section_one_arrow'>
                          <SlArrowDown className='arrow_one'/>
                          <SlArrowDown className='arrow_two'/>
                          <SlArrowDown className='arrow_three'/>
                      </div>
                  </Parallax>
      
                    
                </section>
                <section className='home_section_two'>
                    <p>While you are here,</p>
                    <h1>Go through my respective portfolios</h1>
                    <div className='home_section_two_div'>
                      <div onClick={() => {navigate('/engineering')}}>
                          <h1>Mechanical engineering</h1>
                          <h3>EXPLORE</h3>
                      </div>
                      <div onClick={() => {navigate('/developer')}}>
                          <h1>Software developing</h1>
                          <h3>EXPLORE</h3>
                      </div>
                      <div onClick={() => {navigate('/design')}}>
                          <h1>Design</h1>
                          <h3>EXPLORE</h3>
                      </div>
                      <div  onClick={() => {navigate('/writer')}}>
                          <h1>Writer</h1>
                          <h3>EXPLORE</h3>
                      </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Home